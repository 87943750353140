<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('fundRecord.default[2]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div class="tool flex_sb flex_ac">
        <div>
          <div class="text1">{{ $t("hall.default[1]") }}</div>
          <div class="text2">{{ Number(UserInfo.balance).toFixed(2) }}</div>
          <div class="text3">USDT</div>
        </div>
        <div class="shu"></div>
        <div>
          <div class="text1">≈</div>
          <div class="text2">{{Number(UserInfo.balance*InitData.usdtinfo.price).toFixed(2) }} </div>
          <div class="text3">{{InitData.usdtinfo.sign}}</div>
        </div>
      </div>
      <!-- <van-tabs
              :ellipsis="false"
              :border="false"
              line-width="60"
              v-model="tabsIndex"
              @change="changeTabs"
      >
        <van-tab
                v-for="item in taskTabs"
                :key="item.state"
                style="padding: 0 12px"
        >
          <template #title>
            <div style="margin-top: -20px">
              {{ item.text }}
            </div>
          </template> -->

          <div class="tabList flex_ac">
            <div  v-for="item,index in taskTabs"
                :key="item.state" class="tab_item"  :class="index==tabsIndex?'tab_item_a':''" @click="changeTabs(index)">{{ item.text }}</div>
          </div>

          <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
            <van-list
                    v-model="isLoad"
                    :finished="isFinished"
                    :finished-text="
                listData[tabsIndex].length ? $t('vanPull[0]') : $t('vanPull[1]')
              "
                    @load="onLoad"
                    :class="{ Empty: !listData[tabsIndex].length }"
            >
              <div v-if="tabsIndex == 0" style="margin-top:10px">
                <van-cell
                        class="FundItem"
                        v-for="(item, index) in listData[tabsIndex]"
                        :key="item.dan"
                        :to="`/user/recharge/${item.dan}`"
                >
                  <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
                  <template #title>
                    <div>
                      <span>{{ item.dan }}</span>
                      <span>{{ item.adddate }}</span>
                    </div>
                    <div>
                      <span class="money">+{{ Number(item.money) }}</span>
                      <span>{{ item.status_desc }}</span>
                    </div>
                  </template>
                </van-cell>
              </div>
              <div v-else style="margin-top: 10px">
                <van-cell
                        class="FundItem"
                        v-for="(item, index) in listData[tabsIndex]"
                        :key="item.order_id"
                >
                  <div :class="`icon tag${tabsIndex}`" slot="icon"></div>
                  <template #title>
                    <div>
                      <span class="dan">{{ item.trade_number }}</span>
                      <span>{{ item.trade_time }}</span>
                    </div>
                    <div>
                      <span class="money"
                      >{{ item.jj }}{{ item.trade_amount }}</span
                      >
                      <span>{{
                        /[^\u4e00-\u9fa5]/g.test($t("fundRecord.default[2]"))
                          ? item.remarks
                          : item.trade_dec
                      }}</span>
                    </div>
                  </template>
                </van-cell>
              </div>
            </van-list>
          </van-pull-refresh>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FundRecord",
    components: {},
    props: ["fundState"],
    data() {
      return {
        listData: "",
        isLoad: false,
        isFinished: false,
        isRefresh: false,
        pageNo: 1,
        tabsState: 4,
        tabsIndex: 0,
        taskTabs: [
          { state: 4, text: this.$t("fundRecord.tabs[0]") },
          { state: 3, text: this.$t("fundRecord.tabs[1]") },
        ],
      };
    },
    computed: {},
    watch: {
      $route() {
        if (this.fundState) {
          this.tabsIndex = this.taskTabs.findIndex(
                  (item) => item.state == this.fundState
          );
          this.tabsState = this.fundState;
        } else {
          this.tabsState = 4;
          this.tabsIndex = 0;
        }
        this.getListData("init");
      },
    },
    created() {

      this.listData = this.taskTabs.map((item) => "");
      if (this.fundState) {
        this.tabsIndex = this.taskTabs.findIndex(
                (item) => item.state == this.fundState
        );
        this.tabsState = this.fundState;
      }
      this.$parent.navBarTitle = "";

      this.getListData("init");
      console.log(this.listData);
    },
    mounted() {},
    activated() {},
    destroyed() {},
    methods: {
      onLoad() {
        this.getListData("load");
      },
      changeTabs(index) {
        this.tabsIndex = index
        this.tabsState = this.taskTabs[index].state;
        this.getListData("init");
      },
      getListData(type) {
        this.isLoad = true;
        this.isRefresh = false;
        if (type == "load") {
          this.pageNo += 1;
        } else {
          this.pageNo = 1;
          this.isFinished = false;
        }
        if (this.tabsIndex == 0) {
          this.$Model.GetRechargeRecord(
                  { state: 0, page_no: this.pageNo },
                  (data) => {
                    this.isLoad = false;
                    if (data.code == 1) {
                      if (type == "load") {
                        this.listData[this.tabsIndex] = this.listData[
                                this.tabsIndex
                                ].concat(data.info);
                      } else {
                        this.listData[this.tabsIndex] = data.info;
                      }
                      if (this.pageNo == data.data_total_page) {
                        this.isFinished = true;
                      } else {
                        this.isFinished = false;
                      }
                    } else {
                      this.listData[this.tabsIndex] = "";
                      this.isFinished = true;
                    }
                  }
          );
        } else {
          this.$Model.FundDetails(
                  { trade_type: this.tabsState, page_no: this.pageNo },
                  (data) => {
                    this.isLoad = false;
                    if (data.code == 1) {
                      if (type == "load") {
                        this.listData[this.tabsIndex] = this.listData[
                                this.tabsIndex
                                ].concat(data.list);
                      } else {
                        this.listData[this.tabsIndex] = data.list;
                      }
                      if (this.pageNo == data.data_total_page) {
                        this.isFinished = true;
                      } else {
                        this.isFinished = false;
                      }
                    } else {
                      this.listData[this.tabsIndex] = "";
                      this.isFinished = true;
                    }
                  }
          );
        }
      },
      onRefresh() {
        this.getListData("init");
      },
    },
  };
</script>

<style scoped lang="scss">
  .PageBox {
    overflow: auto;
    padding-top: 40px;
    background: url(~@/assets/img/new/fund_bg.png) no-repeat;
    background-size:  100% auto;
    color: #333;
    background-color: #F5F8FF;
  }
  .PageBox::v-deep  .van-icon{
    color: #fff !important;
  }
  .PageBox::v-deep .van-nav-bar{
    background-color: #0000 !important;
    background: url(~@/assets/img/new/fund_bg.png) no-repeat;
    background-size:  100% auto;
  }
  .van-nav-bar::v-deep .van-nav-bar__title{
    color:#fff;
  }

  .van-list {
    padding: 10px;
  }
  .van-cell {
    margin-top: 5px;
    border-radius: 10px;
    background-color: var(--panel_color);
    &::after{
      border: none ;
    }
  }
  .van-pull-refresh {
    min-height: calc(100vh - 90px);
  }
  .PageBox::v-deep .tool::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -80%;
  }

  .tool {
    width: 92%;
    border-radius: 16px;
    background-color: #fff;
    color: #333;
    height: 120px;
    box-shadow: 0 1px 3px #dbdbdbdb;
    overflow: hidden;
    z-index: 99;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    margin-left: 4%;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    .shu{
      height: 40px;
      width: 1px;
      background: #f1f1f1;
    }
    .text1{
      font-size: 14px;
      color: #333;
    }
    .text2{
      font-weight: 600;
      font-size: 22px;
      color: #000;
      margin: 10px 0;
    }
    .text3{
      font-size: 15px;
      color: #999;
    }
  }
  .van-cell__title {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  .van-cell__title div {
    display: flex;
    flex-direction: column;
  }
  .van-cell__title div:nth-child(2) {
    align-items: flex-end;
  }

  .PageBox ::v-deep  .van-tabs__nav {
    height: 60px;
    background-color: #0000;
    width: 100%;
    margin-top: 10px;
  }

  .PageBox ::v-deep  .van-tab--active {
    // background-color: #5652FD;
  }

  .van-tab__pane {
    background: #fff0;
  }

  .PageBox ::v-deep  .van-tabs__line {
    height: 0px;
  }
  .Site .van-nav-bar__title {
    color: white;
  }
  .PageBox ::v-deep  .van-tab__text {
    width: 90px;
    color: #aaaaaa;
    text-align: center;
    font-size: 18px;
  }
  .PageBox ::v-deep  .van-tabs__wrap {

  }
  .PageBox ::v-deep  .van-tab--active :nth-of-type(1) div {
    background-color: #5652FD;
    border-radius: 20px;
    color: #fff;
  }
  .PageBox ::v-deep  .van-tab--active :nth-of-type(1) div div {
    background: #5652FD;
    color: white;
    border-radius: 20px;
  }
  .myhead {
    position: fixed;
    top: 0px;
    font-size: 18px;
    color: #000;
    background: #fff;
    width: 100%;
    text-align: center;
  }

  .tabList{
    position: relative;
    z-index: 1000;
    margin-top: 20px;
    .tab_item{
      margin-left:4%;
      height: 30px;
      border:1px solid #fff;
      line-height: 30px;
      border-radius: 16px;
      padding: 0 12px;
      background: #fff;
    }
    .tab_item_a{
      border: 1px solid #93DEE8;
    }
  }
</style>
